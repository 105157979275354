<template>
  <div class="announcement">
    <div class="section hero-grey">
      <div class="container">
        <div class="small-container center">
          <h2 class="h2 margin">Documentos</h2>
          <p class="text">
            Instructivos, Circulares y Comunicados
          </p>
        </div>
      </div>
    </div>
    <div class="section padding-bottomss">
      <div class="container">
        <div data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
          <div class="tabs-menu w-tab-menu">
            <a
              v-for="(item, index) in tipo_normativas"
              :key="index"
              :class="
                `tab-link-tab w-inline-block w-tab-link ${
                  item.id == tab ? 'w--current' : ''
                }`
              "
              @click="changeTab(item.id, item.descripcion)"
            >
              <div>{{ item.descripcion }}</div>
            </a>
          </div>
          <div>
            <select
              class="custom_select"
              v-model="gestion"
              @change="getNormativas()"
            >
              <option
                v-for="(item, index) in gestiones"
                :value="item"
                :key="index"
                >{{ item }}</option
              >
            </select>
          </div>
          <div class="tabs-content w-tab-content">
            <div class="w-tab-pane">
              <div class="main-container">
                <div class="card-container" v-if="normativas.length > 0">
                  <div
                    class="card"
                    v-for="(item, index) in normativas"
                    :key="index"
                    :class="index == 0 ? 'card_bg' : ''"
                  >
                    <small class="textss"
                      >Fecha: {{ item.created_at | formatdate }}
                    </small>
                    <h5 class="card__titles">
                      {{ item.cite }}
                    </h5>
                    <p class="card_description margins">
                      {{ item.descripcion }}
                    </p>
                    <p class="card__applys">
                      <a
                        :href="`${serve}/normativas/${item.documento}`"
                        class="btn-download text-small"
                        target="_blank"
                        download
                        >Descargar <i class="fas fa-arrow-right"></i
                      ></a>
                    </p>
                  </div>
                </div>
                <div class="card-container" v-else>
                  <div class="alert">
                    <span class="text-red"
                      >No existen {{ tipo_normativa }} publicados</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-dyn-list">
          <h3>Normativas</h3>
          <table class="responstable small-text">
            <thead>
              <tr>
                <th>Nro.</th>
                <th>Fecha</th>
                <th>Título</th>
                <th>Descripción</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody v-if="base_normativas.length > 0">
              <tr v-for="(item, index) in base_normativas" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.created_at | formatdate }}
                </td>
                <td>
                  {{ item.cite }}
                </td>
                <td>
                  {{ item.descripcion }}
                </td>
                <td>
                  <a
                    :href="`${serve}/normativas/${item.documento}`"
                    class="btn-download text-small"
                    target="_blank"
                    download
                  >
                    Descargar
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-red">No existe normativas.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../router/endpoint";
import axios from "axios";
export default {
  name: "announcement",
  data() {
    return {
      loading: false,
      tipo_normativas: [],
      normativas: [],
      base_normativas: [],
      gestiones: [],
      tab: 2,
      tipo_normativa: "Instructivos",
      gestion: "",
      serve: "",
    };
  },
  mounted() {
    this.serve = Service.getServe();
    this.gestion = new Date().getFullYear();
    for (let index = this.gestion; index > 2012; index--) {
      this.gestiones.push(index);
    }
    this.getTipoNormativas();
    this.getNormativas(this.tab);
  },
  methods: {
    getTipoNormativas() {
      axios
        .get(Service.getBase() + "tipo/normativas", Service.getHeader())
        .then((response) => {
          this.tipo_normativas = response.data.tipo_normativa;
          this.base_normativas = response.data.normativas;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },

    getNormativas() {
      this.normativas = [];
      this.loading = true;
      axios
        .post(
          Service.getBase() + "lista/normativas",
          { tipo_normativa_id: this.tab, gestion: this.gestion },
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.normativas = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    changeTab(tipo_normativa_id, tipo_normativa) {
      this.tab = tipo_normativa_id;
      this.tipo_normativa = tipo_normativa;
      this.getNormativas();
    },
  },
};
</script>

<style scoped></style>
